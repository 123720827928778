<script lang="ts">
	import { goto } from '$app/navigation';
	import Button from 'src/components/design/Button.svelte';
	import Input from 'src/components/design/Input.svelte';
	import Label from 'src/components/design/Label.svelte';
	import Link from 'src/components/design/Link.svelte';
	import { Routes } from 'src/constants/router';

	import { authStore } from 'src/stores/auth';
	import ErrorMessage from '../design/ErrorMessage.svelte';

	let email: string = '';
	let password: string = '';
	let error: any = {
		email: '',
		password: ''
	};

	async function gotoAdminPage() {
		clearError();
		if (!validate()) return;

		await authStore.login(email, password);
		if (authStore.accessToken) {
			goto(Routes.ADMIN);
		}
	}

	function validate() {
		clearError();
		const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (!email) {
			error.email = 'Email is required!';
		} else if (!emailRegex.test(email)) {
			error.email = 'Invalid email format!';
		}
		if (!password) {
			error.password = 'Password is required!';
		}

		return Object.keys(error).every((key) => error[key] === '');
	}

	function clearError() {
		error = {
			email: '',
			password: ''
		};
	}
</script>

<div class="bg-white w-full h-screen flex flex-row content-center justify-center">
	<div class="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 rounded-xl m-auto p-[20px]">
		<img class="logo w-6/12 pb-[50px] mx-auto" alt="img" src="/images/logo.png" />
		<Label text="Email" class="font-light">
			<Input bind:value={email} class="border-2" />
			<ErrorMessage message={error.email} />
		</Label>
		<Label text="Password" class="font-light">
			<Input bind:value={password} type="password" class="border-2" />
			<ErrorMessage message={error.password} />
		</Label>
		<Link
			class="font-medium underline pb-[20px] text-right"
			text="Forgot password ?"
			href={Routes.FORGOT_PASSWORD}
		/>
		<Button
			class="w-full"
			type="primary"
			text="Login"
			on:click={gotoAdminPage}
			disabled={!email || !password || !email.trim() || !password.trim()}
		/>
	</div>
</div>

<style>
</style>
